import React from "react";
import img from "../../blogassets/Blog Inner page Banner04.jpg";
import arrow from "../../blogassets/arrow.svg";
import { Link } from "react-router-dom";
import img1 from "../../blogassets/Blogs Animation Thumbnail04.jpg";
import { useEffect, useRef, useState } from "react";
import { Linear, gsap } from "gsap";
import img2 from "../../blogassets/Blogs Animation Thumbnail05.jpg";
import img3 from "../../blogassets//Blogs Animation Thumbnail08.jpg";
import Explore from "../../../production/Explore";
import { Helmet } from "react-helmet";
import axios from "axios";
import Footer from "../../../../Footer/Footer";
import muheeb from "../../blogassets/muheeb.jpeg";

const Customer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  };
  const render = useRef(null);
  const [lastRow, setLastRow] = useState(null);
  const [para2, setPara2] = useState(null);
  const [para1, setPara1] = useState(null);
  const [para3, setPara3] = useState(null);
  const [para4, setPara4] = useState(null);
  const [para5, setPara5] = useState(null);
  const [para6, setPara6] = useState(null);
  const [para7, setPara7] = useState(null);
  const [para8, setPara8] = useState(null);
  const [para9, setPara9] = useState(null);
  useEffect(() => {
    const lastData = [
      {
        header1: "Listening To Your Customer\r\n",
        para1:
          "A few years ago, a Fortune 500 company called American Family Insurance (AFI) learned that more than half of Americans did not have the cash to cover a $400 emergency expense like a hospital trip, or a window repair. At first, the company thought Americans were in need of budgeting tools. However, when it actually sought customer feedback, they learned that most people were in need of keeping their savings aside to protect against the future.\r\n\r\nIn one case, AFI reps and their design agency, IDEO sat with a working single mother of four in Tennessee and listened to her challenges about constrained budgets. To save money on childcare, and housekeeping, she had put her 11-year-old in charge of her younger siblings, partitioned snacks, and made chore charts for each child. AFI realized that she did not need another brand new financial management app or tool. She needed extra income cushions.\r\n\r\nAfter gathering similar feedback from several places across the United States, AFI launched Moonrise - a digital platform that allowed workers to sign up for shifts with partner organizations through a simple text message interface. Employers who signed up at Moonrise could list open shifts on the platform, and were liable to pay workers as soon as the shifts were done. Voila!\r\n\r\nListening to customers led AFI to launch a whole new business. Within a few months of its launch in 2018, Moonrise had over 7,000 people applying for shifts, more than 7,000 shifts were fulfilled, and over $500,000 was paid to workers.\r\n\r\n\r\n\r\n",
        date: "03/07/2023",
        writer: "Muheeb Syed Saif",
        back: "Go Back",
        title1: "Now Reading",
        cont1: "Listening To Your Customer\r\n",
        title2: "Also Read",
        cont2: "Bing vs Google",
        cont3: "Discovery Workshop",
        header2: "When Should You Listen To Your Customers?\r\n",
        header3: "When you want to establish expectations\r\n",
        para3:
          "A lot of businesses will wait until the product or service launch, and then get the first feedback from their customers. In order for your product or service to receive open arms from the customers, the feedback process must begin during the product design, and development process itself. Product testing is a vital step towards ensuring that you are launching your business to meet an actual need that exists in the market.\r\n",
        header4: "When you want to improve your product\r\n",
        para4:
          "Ongoing customer feedback is an integral part of business growth. Listening to customers is a great way to get real-time, actual feedback about how your product or service is doing. This is why companies such as AirBnB, Uber, or GrubHub, or e-commerce sites such as Amazon, ensure they have customer feedback instantly from the time of check-out until the product is delivered.\r\n",
        header5: "When they are complaining\r\n",
        para5:
          "This should be a no-brainer, and yet many big and small businesses make the mistake of ignoring begrudged customers. Of course, not every complaint that you receive may be genuine, but many people wish to benefit from your return, exchange/service policies. However, it is always advisable to look into a complaint closely to see if there’s anything you are missing out on. Even when you receive too many false complaints, it is an indicator that your system, or CMS, needs improvement in filtering such complaints.\r\n",
        header6: "When you are not growing\r\n",
        para6:
          "In every business’ life, there comes a time when it hits a wall. When sales are steady, but not growing exponentially. This is the time to reach out to your customers, and re-establish their expectations. A great example is how car sports brand Nascar hit a wall back in 2018. When Steve Phelps came in as CEO, he helped create the Official Nascar Fan Council, which is a customer intelligence platform capturing fan insights from more than 12,000 viewers regularly. In addition, Nascar also partnered with Hewlett-Packard for a social media command center to get customer feedback in real time.\r\n\r\n",
        header7: "When you want to reinvent your business\r\n",
        para7:
          "Until 2014, Swarovski had been known around the world (apart from crystals) for producing iconic designs in their range of chandeliers. With the rise of interface technology, the company felt an acute need to catch up with the changing tastes of its customers.\r\n\r\nWith the help of a noted design company, Swarovski acted upon customer feedback. The company launched Infinite Aura, that infused the Internet of Things (IoT) into their lighting offerings with a mobile app that allowed customers to modify light settings based on mood, time of day, occasion, etc. Listening to customers often helps your business reinvent, and diversify.\r\n\r\n",
        header8: "When you should not listen to your customers\r\n",
        para8:
          'In 1909, Harry Gordon Selfridge said, "The customer is not always right." Listening to customers is great for growth, improvement, and reinvention, but it is often not advisable for innovation since most people are resistant to change.\r\n\r\nThere may also be circumstances where the customers may not know what they want, for example, in far-off, remote areas where technology or modern means have not reached, you may come across a business idea that is hard to test among people. In short, cultivating a habit of listening well while filtering out information that you don’t need is the way to go.\r\n\r\n\r\n',
        header9:
          "The Best Ways To Listen To Your Customers More Fruitfully -\r\n",
        para9:
          "#1: Go to where they are\r\nListen to your customers on their preferred channels, such as social media, DMs, or emails.\r\n# 2 Find out the ‘how’\r\nDon’t just listen to your customers, find out how a specific problem is affecting their lives.\r\n# 3 Keep an open mind\r\nYou may have had lots of similar complaints or feedback earlier, yet it is vital to keep an open mind each time you are listening to a new set of customers.\r\n# 4 Hire an agency\r\nIf you think your team is too busy, hire a product development agency that would carry out surveys, research, and interviews on your behalf.\r\nWanna Know A S\r\n\r\n\r\n",
        header10: "Final Thoughts\r\n",
        para10:
          "The use of AI in search engines is not new. The more data is processed each day, these engines evolve continually producing more refined results in the process. One big way these engines use AI is in ranking web pages, videos, or any other content on the web. And this is why businesses need to focus on their SEO strategy to stay on top of the search results. If you need assistance in SEO, do give us a call.\r\n",
      },
    ];
    const lastrow = lastData[0];
    setLastRow(lastrow);
    setPara2(lastrow.para3);
    setPara1(lastrow.para1);
    setPara3(lastrow.para4);
    setPara4(lastrow.para5);
    setPara5(lastrow.para6);
    setPara6(lastrow.para7);
    setPara7(lastrow.para8);
    setPara8(lastrow.para9);
    setPara9(lastrow.para10);
  }, []);
  const marquee1 = useRef(null);
  const marquee2 = useRef(null);
  const marquee3 = useRef(null);

  useEffect(() => {
    gsap
      .timeline({ repeat: -1 })
      .fromTo(
        marquee1.current,
        4,
        { x: 0 },
        { x: -406, ease: Linear.easeNone }
      );
    gsap
      .timeline({ repeat: -1 })
      .fromTo(
        marquee2.current,
        4,
        { x: 0 },
        { x: -406, ease: Linear.easeNone }
      );
    gsap
      .timeline({ repeat: -1 })
      .fromTo(
        marquee3.current,
        4,
        { x: 0 },
        { x: -406, ease: Linear.easeNone }
      );
  });
  const textareaRef2 = useRef(null);
  const textareaRef1 = useRef(null);
  const textareaRef3 = useRef(null);
  const textareaRef4 = useRef(null);
  const textareaRef5 = useRef(null);
  const textareaRef6 = useRef(null);
  const textareaRef7 = useRef(null);
  const textareaRef8 = useRef(null);
  const textareaRef9 = useRef(null);

  const autoAdjustHeight = () => {
    if (textareaRef2.current) {
      textareaRef2.current.style.height = "auto";
      textareaRef2.current.style.height =
        textareaRef2.current.scrollHeight + "px";
    }
    if (textareaRef1.current) {
      textareaRef1.current.style.height = "auto";
      textareaRef1.current.style.height =
        textareaRef1.current.scrollHeight + "px";
    }
    if (textareaRef3.current) {
      textareaRef3.current.style.height = "auto";
      textareaRef3.current.style.height =
        textareaRef3.current.scrollHeight + "px";
    }
    if (textareaRef4.current) {
      textareaRef4.current.style.height = "auto";
      textareaRef4.current.style.height =
        textareaRef4.current.scrollHeight + "px";
    }
    if (textareaRef5.current) {
      textareaRef5.current.style.height = "auto";
      textareaRef5.current.style.height =
        textareaRef5.current.scrollHeight + "px";
    }
    if (textareaRef6.current) {
      textareaRef6.current.style.height = "auto";
      textareaRef6.current.style.height =
        textareaRef6.current.scrollHeight + "px";
    }
    if (textareaRef7.current) {
      textareaRef7.current.style.height = "auto";
      textareaRef7.current.style.height =
        textareaRef7.current.scrollHeight + "px";
    }
    if (textareaRef8.current) {
      textareaRef8.current.style.height = "auto";
      textareaRef8.current.style.height =
        textareaRef8.current.scrollHeight + "px";
    }
    if (textareaRef9.current) {
      textareaRef9.current.style.height = "auto";
      textareaRef9.current.style.height =
        textareaRef9.current.scrollHeight + "px";
    }
  };
  useEffect(() => {
    autoAdjustHeight();
  }, [para2]);
  return (
    <>
      {" "}
      <Helmet>
        <title>Insights - Listening To Your Customer</title>
        <meta
          name="description"
          content="Discover the importance of listening to your customers and how it can drive business success. Learn strategies for gathering customer feedback, improving customer satisfaction, and building strong customer relationships. Stay informed with insights from Codelinear."
        />
        <meta
          name="keywords"
          content="customer feedback, customer satisfaction, customer relationships, listening to customers, customer-centric approach, business success"
        />
      </Helmet>
      <div className="innerhero">
        <Link
          to="/insights"
          onClick={scrollToTop}
          className="px-4 py-2 hidden max-sm:flex left-[80px] top-[90px] absolute rounded-[54px] border border-black justify-center items-center gap-2 inline-flex hover:bg-yellow-400 transition-all  hover:border-yellow-400 max-lg:left-[20px]"
        >
          <Link
            to="/insights"
            onClick={scrollToTop}
            className="w-[31px] h-[21px] relative "
          >
            <img
              className="origin-top-left rotate45 w-[21.83px] h-[21.83px] left-[1.58px] top-5.20px] absolute"
              src={arrow}
              alt=""
            />
          </Link>
          <div className="opacity-80 text-black text-base font-normal font-['Graphik'] leading-normal">
            {lastRow && <>{lastRow.back}</>}
          </div>
        </Link>
        <div className="w-full h-full pl-20 pr-[407px] pt-[233.78px] pb-[65.22px] bg-white justify-start items-center inline-flex max-lg:p-10 max-sm:p-0 max-sm:px-[20px] max-sm:mt-[90px]">
          <div className="w-[953px] letter-spacing-2 text-black text-[66px] font-normal font-['Graphik'] leading-[108%] max-lg:w-full max-sm:text-[50px]">
            {lastRow && <>{lastRow.header1}</>}
          </div>
        </div>
        <div className="text-20  pl-20 max-lg:p-10 max-sm:p-0 max-sm:px-[20px] flex items-center mt-[40px]">
          <img
            className="w-[60px] h-[60px] rounded-[100%] object-cover relative bg-black bg-opacity-20 flex-col justify-start items-start flex"
            src={muheeb}
            alt=""
          />
          <h1 className="ml-[20px]">{lastRow && <>{lastRow.writer}</>}</h1>
          <p className="ml-[40px]">{lastRow && <>{lastRow.date}</>}</p>
        </div>
        <div className="hero-img">
          <div className="w-full h-full px-20 py-[36.50px] bg-white justify-center items-center inline-flex max-md:px-0">
            <img
              className="w-full h-full relative bg-black bg-opacity-20 flex-col justify-start items-start flex"
              src={img}
              alt=""
            />
          </div>
        </div>
      </div>
      <div ref={render} className="blog-content">
        <div className="w-full h-full relative bg-white max-xl:h-full">
          <div className="flex gap-40 max-xl:gap-10 max-xl:flex-wrap max-lg:justify-center">
            <div className="h-[600px] sticky top-10 w-[300px] max-lg:relative max-lg:w-full max-sm:absolute max-sm:top-0 max-lg:h-full">
              <Link
                to="/insights"
                onClick={scrollToTop}
                className="px-4 py-2 max-sm:hidden left-[80px] top-[90px] absolute rounded-[54px] border border-black justify-center items-center gap-2 inline-flex hover:bg-yellow-400 transition-all  hover:border-yellow-400 max-lg:top-[0px] max-lg:left-[20px]"
              >
                <Link
                  to="/insights"
                  onClick={scrollToTop}
                  className="w-[31px] h-[21px] relative "
                >
                  <img
                    className="origin-top-left rotate45 w-[21.83px] h-[21.83px] left-[1.58px] top-5.20px] absolute"
                    src={arrow}
                    alt=""
                  />
                </Link>
                <div className="opacity-80 text-black text-base font-normal font-['Graphik'] leading-normal">
                  {lastRow && <>{lastRow.back}</>}
                </div>
              </Link>

              <div className="left-[80px] top-[201.39px] absolute text-black text-2xl font-normal font-['Graphik'] capitalize max-lg:hidden">
                {lastRow && <>{lastRow.back}</>}
              </div>
              <div className="left-[80px] top-[352.39px] absolute text-black text-2xl font-normal font-['Graphik'] capitalize max-lg:hidden">
                {lastRow && <>{lastRow.title2}</>}
              </div>
              <div className="w-[232px] h-[65px] left-[81px] top-[242px] absolute border border-black border-opacity-10 max-lg:hidden">
                <div className="w-[68px] h-[65px] left-0 top-[-1.39px] absolute bg-black bg-opacity-20">
                  <img className="w-[68px] h-[65px]" src={img1} alt="" />
                </div>
                <div className="flex h-full items-center left-[67.31px] absolute justify-center items-center inline-flex">
                  <div className="h-[24px]   text-black text-base font-normal font-['Graphik'] leading-normal">
                    <div className="overflow-hidden w-[163px]">
                      <p
                        ref={marquee1}
                        className="w-[214px] ml-[170px] whitespace-nowrap"
                      >
                        {lastRow && <>{lastRow.cont1}</>}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Link
                onClick={scrollToTop}
                to="/insights/bing-vs-google"
                className="w-[232px] h-[65px] left-[81px] top-[393px] absolute border border-black border-opacity-10 max-lg:hidden"
              >
                <div className="w-[68px] h-[65px] left-0 top-[-1.39px] absolute bg-black bg-opacity-20">
                  <img className="w-[68px] h-[65px]" src={img2} alt="" />
                </div>
                <div className="flex h-full items-center left-[67.31px] absolute justify-center items-center inline-flex">
                  <div className="h-[24px]   text-black text-base font-normal font-['Graphik'] leading-normal">
                    <div className="overflow-hidden w-[163px]">
                      <p
                        ref={marquee2}
                        className="w-[214px] ml-[170px] whitespace-nowrap"
                      >
                        {lastRow && <>{lastRow.cont2}</>}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                onClick={scrollToTop}
                to="/insights/discovery-workshop"
                className="w-[232px] h-[65px] left-[81px] top-[471px] absolute border border-black border-opacity-10 max-lg:hidden"
              >
                <div className="w-[68px] h-[65px] left-0 top-[-1.39px] absolute bg-black bg-opacity-20">
                  <img className="w-[68px] h-[65px]" src={img3} alt="" />
                </div>
                <div className="flex h-full items-center left-[67.31px] absolute justify-center items-center inline-flex">
                  <div className="h-[24px]   text-black text-base font-normal font-['Graphik'] leading-normal">
                    <div className="overflow-hidden w-[163px]">
                      <p
                        ref={marquee3}
                        className="w-[214px] ml-[170px] whitespace-nowrap"
                      >
                        {lastRow && <>{lastRow.cont3}</>}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="left-[405px] h-[full] mt-[81.39px]  flex-col justify-start items-start gap-14 inline-flex max-md:px-10 max-sm:px-[20px] max-sm:mt-[80px]">
              <textarea
                type="text"
                value={para1}
                disabled
                ref={textareaRef1}
                className="w-[630px] resize-none opacity-80 text-black bg-white text-base font-normal font-['Graphik'] leading-normal max-md:w-full max-sm:text-justify"
              ></textarea>
              <div className="w-full flex-col justify-start items-start gap-5 flex">
                <div className="text-black text-[28px] font-normal font-['Graphik'] leading-[34.16px] max-md:w-full">
                  {lastRow && <>{lastRow.header2}</>}
                </div>
                <div className="text-black text-[28px] font-normal font-['Graphik'] leading-[34.16px] max-md:w-full">
                  {lastRow && <>{lastRow.header3}</>}
                </div>
                <textarea
                  type="text"
                  value={para2}
                  disabled
                  ref={textareaRef2}
                  className="w-[630px] resize-none opacity-80 text-black bg-white text-base font-normal font-['Graphik'] leading-normal max-md:w-full max-sm:text-justify"
                ></textarea>
              </div>
              <div className="w-full flex-col justify-start items-start gap-5 flex">
                <div className="text-black text-[28px] font-normal font-['Graphik'] leading-[34.16px] max-md:w-full">
                  {lastRow && <>{lastRow.header4}</>}
                </div>
                <textarea
                  type="text"
                  value={para3}
                  disabled
                  ref={textareaRef3}
                  className="w-[630px] resize-none opacity-80 text-black bg-white text-base font-normal font-['Graphik'] leading-normal max-md:w-full max-sm:text-justify"
                ></textarea>
              </div>
              <div className="w-full flex-col justify-start items-start gap-5 flex">
                <div className="text-black text-[28px] font-normal font-['Graphik'] leading-[34.16px] max-md:w-full">
                  {lastRow && <>{lastRow.header5}</>}
                </div>
                <textarea
                  type="text"
                  value={para4}
                  disabled
                  ref={textareaRef4}
                  className="w-[630px] resize-none opacity-80 text-black bg-white text-base font-normal font-['Graphik'] leading-normal max-md:w-full max-sm:text-justify"
                ></textarea>
              </div>
              <div className="w-full flex-col justify-start items-start gap-5 flex">
                <div className="text-black text-[28px] font-normal font-['Graphik'] leading-[34.16px] max-md:w-full">
                  {lastRow && <>{lastRow.header6}</>}
                </div>
                <textarea
                  type="text"
                  value={para5}
                  disabled
                  ref={textareaRef5}
                  className="w-[630px] resize-none opacity-80 text-black bg-white text-base font-normal font-['Graphik'] leading-normal max-md:w-full max-sm:text-justify"
                ></textarea>
              </div>
              <div className="w-full flex-col justify-start items-start gap-5 flex">
                <div className="text-black text-[28px] font-normal font-['Graphik'] leading-[34.16px] max-md:w-full">
                  {lastRow && <>{lastRow.header7}</>}
                </div>
                <textarea
                  type="text"
                  value={para6}
                  disabled
                  ref={textareaRef6}
                  className="w-[630px] resize-none opacity-80 text-black bg-white text-base font-normal font-['Graphik'] leading-normal max-md:w-full max-sm:text-justify"
                ></textarea>
              </div>
              <div className="w-full flex-col justify-start items-start gap-5 flex">
                <div className="text-black text-[28px] font-normal font-['Graphik'] leading-[34.16px] max-md:w-full">
                  {lastRow && <>{lastRow.header8}</>}
                </div>
                <textarea
                  type="text"
                  value={para7}
                  disabled
                  ref={textareaRef7}
                  className="w-[630px] resize-none opacity-80 text-black bg-white text-base font-normal font-['Graphik'] leading-normal max-md:w-full max-sm:text-justify"
                ></textarea>
              </div>
              <div className="w-full flex-col justify-start items-start gap-5 flex">
                <div className="text-black text-[28px] font-normal font-['Graphik'] leading-[34.16px] max-md:w-full">
                  {lastRow && <>{lastRow.header9}</>}
                </div>
                <textarea
                  type="text"
                  value={para8}
                  disabled
                  ref={textareaRef8}
                  className="w-[630px] resize-none opacity-80 text-black bg-white text-base font-normal font-['Graphik'] leading-normal max-md:w-full max-sm:text-justify"
                ></textarea>
              </div>

              <div className="flex-col justify-start items-start gap-2 flex w-full">
                <div className="w-[630px] text-black text-xl font-normal font-['Graphik'] leading-[30px] max-md:w-full">
                  {lastRow && <>{lastRow.header10}</>}
                </div>
                <textarea
                  type="text"
                  value={para9}
                  disabled
                  ref={textareaRef9}
                  className="w-[630px] resize-none opacity-80 text-black bg-white text-base font-normal font-['Graphik'] leading-normal max-md:w-full max-sm:text-justify"
                ></textarea>
              </div>
            </div>
          </div>
          <Explore />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Customer;
