import Noise from "./assets/Noise & Texture.svg";
import arrow from "../../Navbar/Frame 1000002996.svg";
import circle from "./assets/Ellipse 15.svg";
import semicircle from "./assets/Ellipse 18.svg";
import ai from "./assets/ai.svg";
import supportai from "./assets/support.svg";
import Footer from "../../Footer/Footer";
import AI from "./assets/curve1.png";
import AI2 from "./assets/curve2.png";
import ai1 from "./assets/material-symbols-light_security (2).svg";
import ai2 from "./assets/ph_gear-light.svg";
import ai3 from "./assets/Picture-Stack-Landscape--Streamline-Ultimate.svg.svg";
import { Link } from "react-router-dom";
import Aicomponent from "./aicomponent";

const Aistudio = () => {
  return (
    <>
      <main className="px-[80px] max-lg:px-[40px] max-sm:px-[20px]">
        <div className="absolute w-full h-[808px] max-[785px]:h-[1000px] max-mini:h-[1210px] absolute top-0 left-0 gradient-color-yellow"></div>
        <section className="w-full overflow-hidden absolute top-0 left-0 max-[785px]:h-[1000px] h-[808px]">
          <img
            src={Noise}
            className="h-[808px] w-full z-20 object-cover absolute soft-light left-0 "
            alt=""
          />
          <img
            src={circle}
            alt=""
            className="w-[434.63px] z-10 absolute top-[225px] left-[490.05px] h-[434.63px]"
          />
          <img
            src={semicircle}
            alt=""
            className="w-[434.63px] z-10 absolute top-[225px] left-[815.37px] h-[434.63px]"
          />
          <img
            src={semicircle}
            alt=""
            className="w-[434.63px] z-10 absolute top-[225px] left-[1033.37px] h-[434.63px]"
          />
        </section>
        <div className="z-30 relative">
          <h1 className="mt-[120px] max-sm:text-[50px] max-sm:leading-[54px] max-[800px]:w-full text-[66.23px] leading-[71.66px] tracking-[-1.9px] mb-[56px] w-[737.69px]">
            Disrupt the AI game by making your own GPT.
          </h1>
          <a
            href="#aitools"
            className="border-[1px] border-[black] w-[214.16px] h-[56px] rounded-[31px] flex gap-[8px] items-center justify-center"
          >
            <p className="text-base">See our models</p>
            <img
              src={arrow}
              className="w-[24px] h-[24px] rotate-[45deg]"
              alt=""
            />
          </a>
          <div className="flex justify-end mb-[62px] mt-[18px]">
            <div className="bg-[#202941e5] max-sm:w-full max-mini:h-auto w-[522px] h-[261px] rounded-[7px]">
              <div className="w-[522px] max-mini:flex-col flex gap-[35.32px] max-sm:w-full max-mini:h-auto max-mini:pb-[20px] pt-[28px] max-mini:px-[20px] pl-[21px] gradient-color-black h-[261px] rounded-[7px]">
                <div>
                  <div className="bg-[#FFFFFF1A] text-[14px] rounded-[32px] flex items-center gap-[4px] w-[52.18px] text-white py-[2px] leading-[21px]">
                    <div className="bg-[#FF0000] rounded-[100%] w-[13.18px] h-[13.18px]"></div>
                    Live
                  </div>
                  <img
                    src={ai}
                    className="w-[165px] max-mini:w-full max-mini:h-auto h-[166px] mt-[14px]"
                    alt=""
                  />
                </div>
                <div className="mt-[39px] max-mini:mt-0">
                  <h1 className="text-[32px] text-white leading-[38.4px] mb-[10px]">
                    Vacation.AI
                  </h1>
                  <p className="text-base text-[#FFFFFFB2]">
                    Planning made easy.
                  </p>
                  <Link to={"/ai-studio/about-vacation-ai"}>
                    <button className="mt-[38px] max-mini:w-full w-[170px] h-[56px] text-white rounded-[31px] bg-[#0F1599] text-base">
                      Start Planning
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <section className="py-[91.5px] bg-[#EDF4F6] overflow-hidden h-[865px] max-md:h-[1600px] relative max-lg:pl-[40px] max-sm:px-[20px] pl-[80px]">
        <img
          src={AI}
          className="absolute h-[1015.04px] w-[2078.73px] object-cover top-[0px] left-0 w-full"
          alt=""
        />
        <img
          src={AI2}
          className="absolute h-[865px] w-[2078.73px] object-cover bottom-0 left-0 w-full"
          alt=""
        />
        <div className="w-[521.42px] max-sm:w-full relative z-10 mb-[41px] max-xl:ml-0 ml-[108px]">
          <div className="w-[521.42px] mb-[30px] justify-start items-center gap-[13.42px] inline-flex max-lg:w-[100%]">
            <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
              Ai studios{" "}
            </div>
            <div className=" grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
          </div>
          <h1 className="text-[49.2px] max-sm:w-full tracking-[-1.47px] text-black w-[471.46px] leading-[59.04px]">
            Accelerate your pipelines with AI
          </h1>
          <p className="text-black opacity-80 mt-[22px] text-base">
            Transform your operations with NLP expertise, leveraging language
            understanding for improved communication, insights, and
            decision-making within your organization.
          </p>
        </div>
        <div className="relative flex gap-[52px] max-xl:ml-0 max-md:flex-col max-hamburger:flex-wrap z-10 mb-[41px] ml-[108px]">
          <div className="w-[273.16px]">
            <img
              src={ai1}
              className="w-[57px] h-[57px] invert mb-[20px]"
              alt=""
            />
            <h1 className="text-[20px] leading-[24px] tracking-[-0.2px] mb-[20px] text-black">
              Chatbot Development
            </h1>
            <p className="text-black opacity-80 text-base mb-[20px]">
              Build interactive chatbots, enhancing customer support and user
              engagement with natural language conversations.
            </p>
            <ul className="list-disc ml-[13px]">
              <li className="text-black opacity-80 text-base">
                Consulting and Strategy
              </li>
              <li className="text-black opacity-80 text-base">
                Chatbot Training
              </li>
              <li className="text-black opacity-80 text-base">Fine-tuning</li>
              <li className="text-black opacity-80 text-base">
                Chatbot Deployment
              </li>
            </ul>
          </div>
          <div className="w-[273.16px]">
            <img
              src={ai2}
              className="w-[57px] h-[57px] invert mb-[20px]"
              alt=""
            />
            <h1 className="text-[20px] leading-[24px] tracking-[-0.2px] mb-[20px] text-black">
              Data Console
            </h1>
            <p className="text-black opacity-80 text-base mb-[20px]">
              Implement NLP into your ecosystem, making it easier for people in
              your organization to make informed decisions.
            </p>
            <ul className="list-disc ml-[13px]">
              <li className="text-black opacity-80 text-base">
                Data preparation
              </li>
              <li className="text-black opacity-80 text-base">
                Train the model on custom data
              </li>
              <li className="text-black opacity-80 text-base">
                Implement conversational <br /> feature
              </li>
              <li className="text-black opacity-80 text-base">
                Deployment on cloud
              </li>
            </ul>
          </div>
          <div className="w-[273.16px]">
            <img
              src={ai3}
              className="w-[57px] h-[57px] invert mb-[20px]"
              alt=""
            />
            <h1 className="text-[20px] leading-[24px] tracking-[-0.2px] mb-[20px] text-black">
              Generative AI
            </h1>
            <p className="text-black opacity-80 text-base mb-[20px]">
              Train and implement AI to generate content, visuals tailored to
              your brand guidelines. Shorten your creative process with
              innovation.
            </p>
            <ul className="list-disc ml-[13px]">
              <li className="text-black opacity-80 text-base">
                Model consulting
              </li>
              <li className="text-black opacity-80 text-base">
                Data preparation
              </li>
              <li className="text-black opacity-80 text-base">
                Model training
              </li>
              <li className="text-black opacity-80 text-base">
                Deployment and maintenance
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div id="aitools">
        <Aicomponent />
      </div>
      <Footer />
    </>
  );
};

export default Aistudio;
